/* styles.css */
.container {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    overflow-x: -webkit-auto;
    overflow-x: -moz-auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #8f8f8f80 transparent;
}

.fixedContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
