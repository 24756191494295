.license-plate-tooltip {
    background-color: #ffffff9f;
    color: #333;
    border: 1px solid #333;
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }
  