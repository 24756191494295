.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1); /* Полупрозрачный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Убедитесь, что загрузка появляется поверх всего остального содержимого */
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Светлая граница загрузки */
    border-top: 8px solid #db7134; /* Синяя верхняя граница загрузки */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Анимация вращения */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  